body {
	padding: 0;
	margin: 0 auto;
    max-width: 1024px;
	background: #E7EBF0;
	font-family: Arial, Helvetica, sans-serif;
}

.availability-result .availability-results-details{
	display:none;
}
.availability-result.expanded .availability-results-details{
	display:block;
}

.availability-result .expand-button{
	display:inline-flex;
}
.availability-result .collapse-button{
	display:none;
}
.availability-result.expanded .expand-button{
	display:none;
}
.availability-result.expanded .collapse-button{
	display:inline-flex;
}